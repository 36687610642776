html,
body,
#root,
#root > div {
  width: 100%;
  background-color: #030303;
  scrollbar-color: red;
  scrollbar-width: thin;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body::-webkit-scrollbar {
  width: 5px;
  background-color: red;
}
body::-webkit-scrollbar-thumb {
  background-color: red;
}

.element-with-scroll {   
  scrollbar-color: red;
  scrollbar-width: thin;
}

.element-with-scroll::-webkit-scrollbar {
  width: 5px;
}

.element-with-scroll::-webkit-scrollbar-thumb {
  background-color: red; 
}

.slick-slide>div{
  margin: 0 30px;
}

.slick-slide>div:nth-child(1){
  margin-left: 0
}

.slick-list{
  margin-right: -30px;
}

.slick-dots li button:before {
  color: #fff; 
  opacity: 1;   
  font-size: 10px;
}

.slick-dots li.slick-active button:before {
  color: white !important; 
  font-size: 14px; 
}